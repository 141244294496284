<template>
  <div>
    <b-container class="m-a">
      <b-row class="text-center m-a justify-content-md-center">
        <b-col cols="6" class="text-right">
          <b-img
            style="width: 50px; height: 50px;"
            class="c-p social-btn"
            src="images/icons/google.png"
          ></b-img>
        </b-col>
        <b-col cols="6" class="text-left">
          <b-img
            style="width: 47px; height: 47px;"
            class="c-p social-btn"
            src="images/icons/fb.png"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>>

<script>
export default {
  name: "social-buttons"
};
</script>

<style lang="css" scoped>
.social-btn {
  transition: 0.3s all;
}
.social-btn:hover {
  transform: scale(1.2);
}
</style>