<template>
  <div class="h-100 w-100">
    <div class="w-100 text-right mb-4">Step {{stepNum}}/5</div>
    <div class="d-flex flex-column align-items-center my-3 w-100">
      <h1 class="title w-100 ta-l mt-1">
        Your Colors
        <span class="subtitle">(Optional)</span>
      </h1>
      <h5
        class="title-sub-text ml-1 w-100"
      >by choosing your colors, it will helps us recommend you reletable content.</h5>
      <div class="d-block preferences-scrollable-div" style="overflow-y: scroll; height: 55vh;">
        <div
          v-for="style in styles"
          :key="style.id"
          @click="selectStyle(style.id)"
          class="tb-square-btn d-inline-block m-2 c-p"
        >
          <div v-if="style.selected" class="tb-square-btn-selected"></div>
          <b-img
            v-if="style.selected"
            class="tb-square-btn-selected-img"
            src="images/icons/checked.png"
          ></b-img>
          <b-img :src="style.image"></b-img>
          <div class="tb-square-btn-label">{{style.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FavColors",
  props: {
    stepNum: {
      type: Number,
      default: 0
    }
  },
  components: {},
  mounted() {},
  methods: {
    selectStyle(styleId) {
      let targetStyle = this.styles.find(style => style.id === styleId);
      targetStyle.selected = !targetStyle.selected;
    }
  },
  data() {
    return {
      gender: null,
      styles: [
        {
          id: 0,
          name: "Warm",
          image: "images/test_data/colors/warm.png",
          selected: false
        },
        {
          id: 1,
          name: "Rainbow",
          image: "images/test_data/colors/rainbow.png",
          selected: false
        },
        {
          id: 2,
          name: "Monochromatic",
          image: "images/test_data/colors/monochromatic.png",
          selected: false
        },
        {
          id: 3,
          name: "Dark",
          image: "images/test_data/colors/dark.png",
          selected: false
        },
        {
          id: 4,
          name: "Vintage",
          image: "images/test_data/colors/vintage.png",
          selected: false
        },
        {
          id: 5,
          name: "Cold",
          image: "images/test_data/colors/cold.png",
          selected: false
        }
      ]
    };
  }
};
</script>

<style scoped>
.title {
  font-size: 2.5em;
  color: black;
  font-weight: 900;
}

.title-sub-text {
  font-weight: 500;
  font-size: 1em;
  color: #474a57;
}

.subtitle {
  font-size: 0.4em;
  color: #474a57;
  font-weight: 500;
}
</style>