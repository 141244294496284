<template>
  <div>
    <div id="preloginPage" class="prelogin-page d-none">
      <h1 class="tb-font w-100 text-center pb-4">Ready to shop and brag!</h1>
      <b-img class="mb-4 w-80" style="max-width: 700px;" src="images/splash/prelogin.png"></b-img>
      <router-link class="w-100" to="/register">
        <input
          type="submit"
          class="tb-btn w-80 m-a mb-3 tb-font"
          style="max-width: 420px"
          value="Get Started!"
        />
      </router-link>
      <a
        onclick="window.location.reload();"
        class="w-100 mt-3 font-weight-bold c-p"
        style="color: black;"
        to="/"
      >
        <u>Back to splash screens</u>
      </a>
    </div>
    <div id="splashPageContainer" class="splash-page-container background-gradient-0">
      <div>
        <b-carousel
          id="splash-carousel"
          v-model="slide"
          class="splash-page-carousel"
          indicators
          controls
          no-wrap
          :interval="interval"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide
            v-for="splashPage in splashPages"
            :key="splashPage.id"
            class="splash-page"
            :img-src="splashPage.img"
            v-bind:class="(splashPage.pullUp)? 'pull-up': ''"
          >
            <div class="logo">
              <img
                id="logo"
                v-if="splashPage.hasLogo"
                class="logo-img animate"
                src="images/logos/logo.png"
                alt
              />
            </div>
            <h1 class="splash-page-text focused">{{splashPage.focusedText}}</h1>
            <h3 class="splash-page-text">{{splashPage.text}}</h3>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "SplashPage",
  components: {},
  // watch: {
  //   $data: {
  //     handler: function(newValue) {
  //       console.log("Current vaules:" + newValue.slide);
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    setOpacityLeftArrow(leftArrows, opacity) {
      for (let i = 0; i < leftArrows.length; i++) {
        leftArrows[i].style.opacity = opacity;
      }
    },
    onSlideEnd() {
      var leftArrows = document.getElementsByClassName(
        "carousel-control-prev-icon"
      );

      if (this.slide > 0) this.setOpacityLeftArrow(leftArrows, 1);
      else this.setOpacityLeftArrow(leftArrows, 0);

      if (this.slide == 4) {
        document.getElementById("splashPageContainer").classList.add("d-none");
        document.getElementById("preloginPage").classList.remove("d-none");
        return;
      }
      for (let i = 0; i < this.splashPages.length; i++) {
        if (this.slide != i) {
          document
            .getElementById("splashPageContainer")
            .classList.remove("background-gradient-" + i);
        }
      }
      document
        .getElementById("splashPageContainer")
        .classList.add("background-gradient-" + this.slide);
    }
  },
  mounted() {
    window.setTimeout(function() {
      document.getElementById("logo").classList.remove("animate");
    }, 5000);
    document
      .getElementById("spRightArrow")
      .addEventListener("click", function() {
        document.getElementById("demo").innerHTML = "Hello World";
      });
  },
  data() {
    return {
      slide: 0,
      interval: 5000,
      splashPages: [
        {
          id: 0,
          focusedText: "",
          text: "",
          img: "images/splash/splash1.png",
          hasLogo: true,
          pullUp: false
        },
        {
          id: 1,
          focusedText: "Social",
          text: "online shopping experience",
          img: "images/splash/splash2.png",
          hasLogo: false,
          pullUp: true
        },
        {
          id: 2,
          focusedText: "Brag",
          text: "your style interact with friends",
          img: "images/splash/splash3.png",
          hasLogo: false,
          pullUp: true
        },
        {
          id: 3,
          focusedText: "Live",
          text: "real time shopping experience",
          img: "images/splash/splash4.png",
          hasLogo: false,
          pullUp: false
        },
        {
          id: 4,
          focusedText: "Live",
          text: "real time shopping experience",
          img: "",
          hasLogo: false,
          pullUp: false,
          hideImage: true
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.splash-page-container {
  height: 100vh;
}

.prelogin-page {
  height: 100vh;
  width: 100%;
  animation: fade-in 2s ease;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.background-gradient-0 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    radial-gradient(
      137.4% 280.16% at -24.51% -37.4%,
      #ed3305 5.61%,
      #ffde9d 23.29%,
      #ff8f43 58.09%,
      #fd5917 73.68%
    );
}

.background-gradient-1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(124.55deg, #db8925 13.74%, #fea843 53.71%, #c17209 85.24%);
}

.background-gradient-2,
.background-gradient-3 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(113.51deg, #ff8f43 68.15%, #fc672f 98.23%);
}

.splash-page-carousel {
  text-shadow: 0px 0px 2px #000;
  margin: auto;
  max-width: 550px;
  height: 100vh;
}

.splash-page-text {
  font-family: "Mabry Pro";
  font-style: normal;
  font-weight: bolder;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
}

.focused {
  font-size: 65px;
}

.logo-img {
  position: relative !important;
  top: -160px;
  max-width: 260px;
}

.animate {
  animation: zoom-in 2s ease;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.1, 0.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
