<template>
  <div class="d-flex items-center">
    <div class="story-profile-pic d-flex rounded-circle shadow-sm">
      <b-img class="w-100 h-100 rounded-circle" :src="title.img"></b-img>
    </div>
    <div class="font-bold text-white ml-2 m-a">
      {{ title.name }}
      <span
        class="font-weight-light text-sm"
        style="color: darkgray;"
      >• {{title.timeAgo}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoryHeader",
  props: {
    title: {
      type: Object,
      default: () => ({
        img: "images/defaults/profile_pic.png",
        name: "T-Brager",
        timeAgo: "now"
      })
    }
  }
};
</script>

<style scoped>
.story-profile-pic {
  width: 30px;
  height: 30px;
  background-color: white;
  font-size: x-large;
  justify-content: center;
  align-items: center;
}
</style>