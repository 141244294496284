<template>
  <div class="post-card tb-card-shadow tb-font">
    <b-container>
      <b-row>
        <b-col cols="2" md="auto" style="min-width: 85px;">
          <b-img src="images/test_data/islam.png" class="avatar rounded-circle c-p"></b-img>
        </b-col>
        <b-col cols="6" md="auto" class="ml-1 text-left" style="max-width: 50%;">
          <b-row>
            <div class="c-p" style="font-size: larger">Islam Gamal</div>
          </b-row>
          <b-row class="secondary-text lighter">has rated this item</b-row>
          <b-row>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star checked"></span>
            <span class="fa fa-star"></span>
            <span class="fa fa-star"></span>
          </b-row>
        </b-col>
        <b-col class="ml-auto mr-2" md="auto" cols="1">
          <b-img class="c-p" src="images/icons/options.png"></b-img>
        </b-col>
      </b-row>
      <b-row class="mt-4 mb-3">
        <b-img-lazy class="w-100" src="images/test_data/hoodie.png"></b-img-lazy>
      </b-row>
      <b-row class="text-left">
        <b-col cols="7" md="auto">
          <b-img
            :id="'likeBtn-' + postId"
            @mouseover="showRedHeart(postId)"
            @mouseleave="hideRedHeart(postId)"
            @click="toggleLikePost(postId)"
            class="interaction-btn mr-3 c-p"
            src="images/icons/like.png"
          ></b-img>
          <b-img class="interaction-btn mx-3 c-p" src="images/icons/comment.png"></b-img>
          <b-img class="interaction-btn mx-3 c-p" src="images/icons/dm.png"></b-img>
        </b-col>
        <b-col cols="5" md="auto" class="ml-auto text-right">
          <b-img class="interaction-btn mx-3 c-p" src="images/icons/idea.png"></b-img>
          <b-img class="interaction-btn mx-3 c-p" src="images/icons/hanger.png"></b-img>
        </b-col>
      </b-row>
      <b-row class="mt-2 text-left lighter" style="font-size: 1.1rem" cols="12">
        <b-col cols="12">
          <b>{{(isLiked)?'You' : 'Islam'}}</b> and
          <b>{{likesCount}} others</b> like this.
        </b-col>
      </b-row>
      <b-row class="mt-3 text-left" style="font-size: 2rem">
        <b-col cols="10">Cropped polo-neck jumper</b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "post-card",
  data() {
    return {
      isLiked: false,
      likesCount: 150
    };
  },
  props: {
    postId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    showRedHeart(postId) {
      console.log("hi");
      document.getElementById("likeBtn-" + postId).src =
        "images/icons/liked.png";
    },
    hideRedHeart(postId) {
      if (!this.isLiked) {
        document.getElementById("likeBtn-" + postId).src =
          "images/icons/like.png";
      }
    },
    toggleLikePost(postId) {
      if (this.isLiked) {
        this.isLiked = false;
        this.hideRedHeart(postId);
        this.likesCount--;
      } else {
        this.isLiked = true;
        this.showRedHeart(postId);
        this.likesCount++;
      }
    }
  }
};
</script>

<style scoped>
.post-card {
  padding: 30px 10px;
  width: 100%;
  background-color: #fafafc;
  height: fit-content;
  min-width: 310px;
}

.checked {
  color: orange;
}

.avatar {
  width: 65px;
  height: 65px;
}

.interaction-btn {
  width: 30px;
}

@media screen and (max-width: 1040px) {
  .interaction-btn {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}

@media screen and (max-width: 860px) {
  .interaction-btn {
    width: 24px;
  }
}
</style>