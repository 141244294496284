<template>
  <div class="login-register-form-top-container">
    <div class="login-register-form-container">
      <b-card no-body style="width: 100%; max-width: 900px;">
        <b-row no-gutters>
          <b-col md="6" class="register-form-img">
            <a href="/">
              <b-card-img src="images/splash/register.png" alt="Image" class="rounded-0 c-p"></b-card-img>
            </a>
          </b-col>
          <b-col md="6" class="register-form">
            <b-card-body style="align-text: left !important;">
              <div class="m-3">
                <h1 class="title tb-font ta-l mb-3">Register</h1>
                <form class="form-group">
                  <input type="email" value="admin@admin" class="disappear" />
                  <input type="password" class="disappear" />

                  <input
                    v-model="user.name"
                    type="name"
                    class="form-control tb-input mb-2 bg-account"
                    placeholder="Full Name"
                    required
                  />
                  <input
                    v-model="user.email"
                    type="email"
                    class="form-control tb-input mb-2 bg-email"
                    placeholder="Email address"
                    required
                  />
                  <input
                    v-model="user.password"
                    type="password"
                    class="form-control tb-input mb-2 bg-password"
                    placeholder="Password"
                    required
                  />
                  <input
                    :class="(confirmPassword == user.password)? 'bg-match': 'bg-miss-match'"
                    v-model="confirmPassword"
                    type="password"
                    class="form-control tb-input mb-4"
                    placeholder="Repeat password"
                    required
                  />
                  <router-link to="/preferences">
                    <input type="submit" class="tb-btn w-100 mb-3 tb-font" value="Register" />
                  </router-link>
                  <p>
                    Already have an account?
                    <router-link to="/login">Sign in here</router-link>
                  </p>
                  <social-buttons />
                </form>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import SocialButtons from "@/components/SocialButtons.vue";

export default {
  name: "RegisterPage",
  components: {
    SocialButtons
  },
  mounted() {},
  data() {
    return {
      confirmPassword: null,
      user: {
        email: "",
        password: ""
      }
    };
  }
};
</script>

<style lang="css" scoped>
@-webkit-keyframes run-down-to-up {
  0% {
    margin-top: 200%;
  }
  100% {
    margin-top: 0%;
  }
}

@-webkit-keyframes run-up-to-down {
  0% {
    margin-top: -200%;
  }
  100% {
    margin-top: 0%;
  }
}

.register-form {
  display: flex;
  align-items: center;
  -webkit-animation: linear;
  -webkit-animation-name: run-down-to-up;
  -webkit-animation-duration: 0.5s;
}

.register-form-img {
  -webkit-animation: linear;
  -webkit-animation-name: run-up-to-down;
  -webkit-animation-duration: 0.5s;
}

.forgot-password {
  color: black;
  text-decoration: underline;
  font-size: 1vw;
}

.bg-account {
  background: url(../../public/images/icons/account.png) no-repeat scroll 6px
    6px;
  padding-left: 40px;
}

.bg-email {
  background: url(../../public/images/icons/email.png) no-repeat scroll 6px 6px;
  padding-left: 40px;
}

.bg-password {
  background: url(../../public/images/icons/password.png) no-repeat scroll 6px
    6px;
  padding-left: 40px;
}

.bg-match {
  background: url(../../public/images/icons/correct.png) no-repeat scroll 6px
    6px;
  padding-left: 40px;
}

.bg-miss-match {
  background: url(../../public/images/icons/miss-match.png) no-repeat scroll 6px
    6px;
  padding-left: 40px;
}

@media screen and (max-width: 767px) {
  .register-form-img {
    height: 250px;
    overflow: hidden;
  }
}
</style>
