<template>
  <div>
    <navbar />
    <div class="home-container-lg">
      <stories-bar class="my-3" />
      <lives-bar class="my-3" />
    </div>
    <div class="home-container-sm">
      <post-card v-for="i in 10" :key="i" :postId="i" class="my-4 px-3" />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import StoriesBar from "@/components/home/StoriesBar.vue";
import PostCard from "@/components/home/PostCard.vue";
import LivesBar from "@/components/home/LivesBar.vue";

export default {
  name: "home-page",
  components: {
    Navbar,
    StoriesBar,
    PostCard,
    LivesBar
  }
};
</script>

<style scoped>
.home-container-lg {
  padding-top: 80px;
  width: 55%;
  margin: auto;
  min-width: 320px;
}
.home-container-sm {
  width: 38%;
  margin: auto;
  min-width: 320px;
  max-width: 500px;
}

@media screen and (max-width: 1000px) {
  .home-container-lg {
    width: 90%;
    padding-left: 10px;
  }
  .home-container-sm {
    width: 70%;
  }
}

@media screen and (max-width: 767px) {
  .home-container-lg {
    width: 100%;
    padding-left: 10px;
  }
  .home-container-sm {
    width: 90%;
  }
}
</style>