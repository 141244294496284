<template>
  <div class="login-register-form-top-container">
    <div
      id="pref-prev-btn"
      @click="showStep(--currentStepNum)"
      class="nav-arrow-pref tb-left-arrow c-p"
    ></div>
    <div class="login-register-form-container">
      <b-card no-body style="width: 100%; max-width: 900px;">
        <b-row no-gutters>
          <b-col md="6" class="let-us-know-you-container d-flex align-items-center">
            <div class="let-us-know-you-text tb-font-new">Let us Know You More</div>
          </b-col>
          <b-col class="let-us-know-you-form" md="6">
            <b-card-body class="tb-font-new text-left h-100 overflow-hidden">
              <Gender v-show="currentStepNum == 1" stepNum="1" />
              <StyleDNA v-show="currentStepNum == 2" stepNum="2" />
              <Sizes v-show="currentStepNum == 3" stepNum="3" />
              <Colors v-show="currentStepNum == 4" stepNum="4" />
              <Shops v-show="currentStepNum == 5" stepNum="5" />
              <div class="row prev-next-mobile-btns">
                <div
                  id="pref-prev-btn-2"
                  @click="showStep(--currentStepNum)"
                  class="m-2 tb-left-arrow c-p"
                ></div>
                <div
                  id="pref-next-btn-2"
                  @click="showStep(++currentStepNum)"
                  class="m-2 tb-right-arrow c-p"
                ></div>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div
      id="pref-next-btn"
      @click="showStep(++currentStepNum)"
      class="tb-right-arrow nav-arrow-pref c-p"
    ></div>
  </div>
</template>

<script>
import Gender from "@/components/preferences/Gender.vue";
import StyleDNA from "@/components/preferences/StyleDNA.vue";
import Sizes from "@/components/preferences/Sizes.vue";
import Colors from "@/components/preferences/Colors.vue";
import Shops from "@/components/preferences/Shops.vue";

export default {
  name: "PreferencesPage",
  components: {
    Gender,
    StyleDNA,
    Sizes,
    Colors,
    Shops
  },
  mounted() {},
  methods: {
    showStep(stepNum) {
      if (stepNum > 5) {
        this.$router.push("/home");
        return;
      }

      if (stepNum <= 1) {
        stepNum = 1;
        document.getElementById("pref-prev-btn").style.opacity = 0;
        document.getElementById("pref-prev-btn-2").style.opacity = 0;
      } else {
        this.currentStepNum = stepNum;
        document.getElementById("pref-prev-btn").style.opacity = 1;
        document.getElementById("pref-prev-btn-2").style.opacity = 1;
      }

      this.currentStepNum = stepNum;
    },
    selectGender(gender) {
      if (gender === "male") {
        this.preferences.gender = "male";
        document.getElementById("male-btn").classList.remove("alt-btn-color");
        document.getElementById("female-btn").classList.add("alt-btn-color");
      } else {
        this.preferences.gender = "female";
        document.getElementById("male-btn").classList.add("alt-btn-color");
        document.getElementById("female-btn").classList.remove("alt-btn-color");
      }
    }
  },
  data() {
    return {
      currentStepNum: 1,
      preferences: {
        gender: null
      }
    };
  }
};
</script>

<style lang="css" scoped>
.let-us-know-you-container {
  height: 80vh;
  background-color: #ca5b28;
  animation: linear;
  border: 1px solid #000000;
  animation-name: run-left-to-right;
  animation-duration: 0.5s;
}

.let-us-know-you-text {
  width: 70%;
  margin: auto;
  font-size: 6vw;
  line-height: 7vw;
  text-align: center;
  color: #fafafa;
  text-shadow: 3px 3px 0px #18191f, 5px 5px 0px #747474, 7px 7px 0px #1a1a1a,
    10px 10px 0px #000000;
  transform: scale(1.1, 1);
}

.let-us-know-you-form {
  height: 80vh;
  animation: linear;
  animation-name: run-right-to-left;
  animation-duration: 0.5s;
}

.nav-arrow-pref {
  width: fit-content;
  margin: 10px;
}

#pref-prev-btn {
  opacity: 0;
}

.prev-next-mobile-btns {
  display: none;
  position: relative;
  justify-content: space-between;
  margin-top: -150px;
  opacity: 0.8;
  z-index: 99;
}
@-webkit-keyframes run-right-to-left {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@-webkit-keyframes run-left-to-right {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@media screen and (max-width: 767px) {
  .prev-next-mobile-btns {
    display: flex;
  }
  .let-us-know-you-container {
    height: fit-content;
  }
  .let-us-know-you-text {
    padding: 20px;
    text-shadow: 2px 2px 0px #18191f, 3px 3px 0px #747474, 4px 4px 0px #1a1a1a,
      5px 5px 0px #000000 !important;
  }
  #pref-prev-btn,
  #pref-next-btn {
    display: none;
  }
}
</style>
