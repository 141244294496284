<template>
  <div class="login-register-form-top-container">
    <div class="login-register-form-container">
      <b-card no-body style="width: 100%; max-width: 900px;">
        <b-row no-gutters>
          <b-col md="6" class="login-form-img">
            <a href="/">
              <b-card-img src="images/splash/register.png" alt="Image" class="rounded-0 c-p"></b-card-img>
            </a>
          </b-col>
          <b-col md="6" class="login-form">
            <b-card-body style="align-text: left !important;">
              <div class="m-3">
                <h1 class="title tb-font ta-l mb-3">Login</h1>
                <form class="form-group">
                  <input type="email" value="admin@admin" class="disappear" />
                  <input type="password" class="disappear" />
                  <input
                    v-model="user.email"
                    type="email"
                    class="form-control tb-input mb-3 bg-email"
                    placeholder="Email address"
                    required
                  />
                  <input
                    v-model="user.password"
                    type="password"
                    class="form-control tb-input mb-1 bg-password"
                    placeholder="Password"
                    required
                  />
                  <p class="ta-l">
                    <a class="forgot-password" href="#">Forgot password?</a>
                  </p>
                  <router-link to="/home">
                    <input type="submit" class="tb-btn w-100 mb-3 tb-font" value="Login" />
                  </router-link>
                  <p>
                    Are you new?
                    <router-link to="/register">Register here</router-link>
                  </p>
                  <social-buttons />
                </form>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SocialButtons from "@/components/SocialButtons.vue";
export default {
  name: "LoginPage",
  components: {
    SocialButtons
  },
  mounted() {},
  data() {
    return {
      user: {
        email: "",
        password: ""
      }
    };
  }
};
</script>

<style lang="css" scoped>
@-webkit-keyframes run-right-to-left {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@-webkit-keyframes run-left-to-right {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

.login-form {
  display: flex;
  align-items: center;
  -webkit-animation: linear;
  -webkit-animation-name: run-right-to-left;
  -webkit-animation-duration: 0.5s;
}

.login-form-img {
  -webkit-animation: linear;
  -webkit-animation-name: run-left-to-right;
  -webkit-animation-duration: 0.5s;
}

.forgot-password {
  color: black;
  text-decoration: underline;
}

.bg-email {
  background: url(../../public/images/icons/email.png) no-repeat scroll 6px 6px;
  padding-left: 40px;
}

.bg-password {
  background: url(../../public/images/icons/password.png) no-repeat scroll 6px
    6px;
  padding-left: 40px;
}

@media screen and (max-width: 767px) {
  .login-form-img {
    height: 250px;
    overflow: hidden;
  }
}
</style>
