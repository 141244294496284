<template>
  <div class="h-100 w-100">
    <div class="w-100 text-right mb-4">Step {{stepNum}}/5</div>
    <div class="d-flex flex-column align-items-center my-3 w-100">
      <h1 class="title w-100 ta-l mt-1">Style DNA</h1>
      <h5
        class="title-sub-text ml-1 w-100"
      >choose your favorite styles with us and set up your wardrobe.</h5>
      <div class="d-block preferences-scrollable-div">
        <div
          v-for="style in styles"
          :key="style.id"
          @click="selectStyle(style.id)"
          class="tb-square-btn d-inline-block m-2 c-p"
        >
          <div v-if="style.selected" class="tb-square-btn-selected"></div>
          <b-img
            v-if="style.selected"
            class="tb-square-btn-selected-img"
            src="images/icons/checked.png"
          ></b-img>
          <b-img :src="style.image"></b-img>
          <div class="tb-square-btn-label">{{style.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StyleDNA",
  props: {
    stepNum: {
      type: Number,
      default: 0
    }
  },
  components: {},
  mounted() {},
  methods: {
    selectStyle(styleId) {
      let targetStyle = this.styles.find(style => style.id === styleId);
      targetStyle.selected = !targetStyle.selected;
    }
  },
  data() {
    return {
      gender: null,
      styles: [
        {
          id: 0,
          name: "Vintage",
          image: "images/test_data/styles/vintage.png",
          selected: false
        },
        {
          id: 1,
          name: "Business Casual",
          image: "images/test_data/styles/business_casual.png",
          selected: false
        },
        {
          id: 2,
          name: "Sporty",
          image: "images/test_data/styles/sporty.png",
          selected: false
        },
        {
          id: 3,
          name: "Elegant",
          image: "images/test_data/styles/elegant.png",
          selected: false
        },
        {
          id: 4,
          name: "90's",
          image: "images/test_data/styles/90s.png",
          selected: false
        },
        {
          id: 5,
          name: "Casual",
          image: "images/test_data/styles/casual.png",
          selected: false
        },
        {
          id: 6,
          name: "Denim",
          image: "images/test_data/styles/denim.png",
          selected: false
        },
        {
          id: 7,
          name: "Street Style",
          image: "images/test_data/styles/street_style.png",
          selected: false
        }
      ]
    };
  }
};
</script>

<style scoped>
.title {
  font-size: 2.5em;
  color: black;
  font-weight: 900;
}

.title-sub-text {
  font-weight: 500;
  font-size: 1em;
  color: #474a57;
}

.more-btn-size {
  height: 60px !important;
  font-size: 1.4em;
}
</style>