<template>
  <div id="scrollContainer" class="stories-bar-container">
    <b-img
      id="left-arrow"
      @click="scrollStories('left')"
      class="nav-arrow pos-left d-none"
      src="images/icons/left-arrow.png"
    ></b-img>
    <div @scroll="rerenderArrows(null)" id="storiesBar" class="stories-bar">
      <div id="storiesBarScroll" class="stories">
        <div
          v-for="header in headers"
          :key="header.id"
          class="story-head story-size circle mx-2 c-p"
          :class="header.viewedAll? '': 'story-border'"
        >
          <figure v-b-modal="'header-' + header.id">
            <b-img :src="header.image" class="story-img circle story-img-size" />
            <figcaption class="story-name mt-2" style="color: black;">{{header.name}}</figcaption>
          </figure>
          <b-modal :id="'header-' + header.id">
            <div
              @click="$bvModal.hide('header-' + header.id)"
              class="position-absolute c-p story-close-btn"
            >×</div>
            <Stories
              :currentIndex="getLastNonViewedIndex(header.id)"
              @storyEnd="markStoryAsViewed($event, header.id)"
              @allStoriesEnd="markHeaderAsViewed(header.id)"
              :stories="header.stories"
            >
              <template slot="header">
                <story-header
                  :title="{name: header.name, img: header.image, timeAgo: header.stories[0].timeAgo}"
                />
              </template>
            </Stories>
          </b-modal>
        </div>
      </div>
    </div>
    <b-img
      id="right-arrow"
      @click="scrollStories('right')"
      class="nav-arrow pos-right"
      src="images/icons/right-arrow.png"
    ></b-img>
  </div>
</template>

<script>
import { Stories } from "vue-insta-stories";
import StoryHeader from "@/components/home/StoryHeader.vue";

export default {
  name: "stories-bar",
  components: {
    Stories,
    StoryHeader
  },
  methods: {
    rerenderArrows(newScrollLeft) {
      if (newScrollLeft == null)
        newScrollLeft = document.getElementById("storiesBar").scrollLeft;

      if (newScrollLeft <= 30)
        document.getElementById("left-arrow").classList.add("d-none");
      else document.getElementById("left-arrow").classList.remove("d-none");

      const storiesBarScroll = document.getElementById("storiesBarScroll");
      const storiesBarContainer = document.getElementById("scrollContainer");
      const maxScrollLeft =
        storiesBarScroll.scrollWidth - storiesBarContainer.clientWidth;

      if (newScrollLeft >= maxScrollLeft)
        document.getElementById("right-arrow").classList.add("d-none");
      else document.getElementById("right-arrow").classList.remove("d-none");
    },
    calculateScrollValue(direction) {
      const clearance = 0.7;
      const storiesBar = document.getElementById("storiesBar");
      const offset = storiesBar.offsetWidth * clearance;
      const newScrollLeft =
        direction == "left"
          ? storiesBar.scrollLeft - offset
          : storiesBar.scrollLeft + offset;
      return newScrollLeft;
    },
    scrollStories(direction) {
      const storiesBar = document.getElementById("storiesBar");
      const newScrollLeft = this.calculateScrollValue(direction);
      this.rerenderArrows(newScrollLeft);
      storiesBar.scrollLeft = newScrollLeft;
    },
    moveHeaderToTheEnd(targetHeader) {
      var index = this.headers.indexOf(targetHeader);
      if (index !== -1) {
        this.headers.splice(index, 1);
        this.headers.push(targetHeader);
      }
    },
    markHeaderAsViewed(headerId) {
      let targetHeader = this.headers.find(header => header.id === headerId);
      targetHeader.viewedAll = true;
      this.moveHeaderToTheEnd(targetHeader);
      // this.$bvModal.hide("header-" + headerId);
    },
    markStoryAsViewed(storyId, headerId) {
      let targetHeader = this.headers.find(header => header.id === headerId);
      targetHeader.stories[storyId].viewed = true;
    },
    getLastNonViewedIndex(headerId) {
      let targetHeaderStories = this.headers.find(
        header => header.id === headerId
      ).stories;

      for (let i = 0; i < targetHeaderStories.length; i++) {
        if (targetHeaderStories[i].viewed == false) {
          return i;
        }
      }
      return 0;
    }
  },
  data() {
    return {
      headers: [
        {
          id: 0,
          image: "images/test_data/islam.png",
          name: "Islam",
          viewedAll: false,
          stories: [
            {
              url: "images/splash/splash1.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 1,
          image: "images/test_data/ibrahim.png",
          name: "Ibrahim",
          viewedAll: false,
          stories: [
            {
              url: "images/splash/splash1.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 6,
          image: "images/test_data/shops/zara.png",
          name: "Zara",
          viewedAll: false,
          stories: [
            {
              url: "images/test_data/shops/zara.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/test_data/islam.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 2,
          image: "images/test_data/yosra.png",
          name: "Yosra",
          viewedAll: false,
          stories: [
            {
              url: "images/test_data/yosra.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 4,
          image: "images/test_data/shops/uspolo.png",
          name: "U.S. Polo",
          viewedAll: false,
          stories: [
            {
              url: "images/test_data/shops/uspolo.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 3,
          image: "images/test_data/maradny.png",
          name: "Maradny",
          viewedAll: false,
          stories: [
            {
              url: "images/test_data/maradny.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 5,
          image: "images/test_data/shops/adidas.png",
          name: "Adidas",
          viewedAll: false,
          stories: [
            {
              url: "images/test_data/shops/adidas.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 6,
          image: "images/test_data/shops/nike.png",
          name: "Nike",
          viewedAll: false,
          stories: [
            {
              url: "images/test_data/shops/nike.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 7,
          image: "images/test_data/shops/under_armour.png",
          name: "U.Armour",
          viewedAll: false,
          stories: [
            {
              url: "images/test_data/shops/under_armour.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        },
        {
          id: 8,
          image: "images/test_data/yosra.png",
          name: "Yosra",
          viewedAll: false,
          stories: [
            {
              url: "images/test_data/yosra.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash2.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            },
            {
              url: "images/splash/splash3.png",
              type: "image",
              duration: 4000,
              timeAgo: "2h",
              viewed: false

              //seeMore: { label: "See more!" }
            }
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.stories-bar-container {
  width: 100%;
  position: relative;
  background-color: #fff;
}

.stories-bar {
  scroll-behavior: smooth;
  position: relative;
  width: 100%;
  height: 130px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.stories-bar::-webkit-scrollbar {
  display: none;
}

.stories {
  overflow-x: auto;
  white-space: nowrap;
  width: max-content;
  height: inherit;
}

.story-head {
  display: flex;
  float: left;
  overflow: hidden;
}

.story-close-btn {
  top: 15px;
  right: 20px;
  font-size: 25px;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
}

.circle {
  border-radius: 50%;
}

.story-size {
  width: 70px;
  height: 70px;
}

.story-img-size {
  width: 66px;
  height: 66px;
}

.story-img {
  padding: 3px;
  position: relative;
  margin: auto;
}

.story-border {
  border: 2px solid #e35332;
}

.scoll-pane::-webkit-scrollbar {
  display: none !important;
}

.story-name {
  color: black;
  position: absolute;
  margin: auto;
  width: 64px;
}

.story {
  /* Take the rest of the page */
  flex-grow: 1;
  /* Center align */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>