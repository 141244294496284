<template>
  <div class="h-100 w-100">
    <div class="w-100 text-right mb-4">Step {{stepNum}}/5</div>
    <div class="d-flex flex-column align-items-center my-3 p-4 w-100">
      <h1 class="title w-100 ta-l mt-5">Gender</h1>
      <h5
        class="title-sub-text ml-1 w-100"
      >choose your gender to identify yourself to the app recommendations</h5>
      <div class="preferences-form-content m-5 w-100">
        <input
          id="male-btn"
          type="submit"
          @click="selectGender('male')"
          class="tb-btn tb-font-new more-btn-size w-100 mb-4 tb-font alt-btn-color"
          value="Male"
        />
        <input
          id="female-btn"
          type="submit"
          @click="selectGender('female')"
          class="tb-btn tb-font-new more-btn-size w-100 mb-4 tb-font alt-btn-color"
          value="Female"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenderSelection",
  props: {
    stepNum: {
      type: Number,
      default: 0
    }
  },
  components: {},
  mounted() {},
  methods: {
    selectGender(gender) {
      if (gender === "male") {
        this.gender = "male";
        document.getElementById("male-btn").classList.remove("alt-btn-color");
        document.getElementById("female-btn").classList.add("alt-btn-color");
      } else {
        this.gender = "female";
        document.getElementById("male-btn").classList.add("alt-btn-color");
        document.getElementById("female-btn").classList.remove("alt-btn-color");
      }
    }
  },
  data() {
    return {
      gender: null
    };
  }
};
</script>

<style scoped>
.title {
  font-size: 2.5em;
  color: black;
  font-weight: 900;
}

.title-sub-text {
  font-weight: 500;
  font-size: 1em;
  color: #474a57;
}

.preferences-form-content {
}

.more-btn-size {
  height: 60px !important;
  font-size: 1.4em;
}
</style>