<template>
  <div>
    <b-navbar
      fixed="top"
      toggleable="lg"
      type="light"
      class="tb-navbar tb-card-shadow remove-border"
    >
      <b-navbar-brand class="logo" href="#">
        <b-img src="images/logos/flat_logo.png" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="m-auto">
          <!-- <b-nav-form class="m-a"> -->
          <!-- <div class="search-container"> -->
          <b-form-input size="sm" class="mr-sm-2 m-a" placeholder="Search"></b-form-input>
          <b-nav-item class="mr-3">
            <b-img src="images/icons/search.png"></b-img>
          </b-nav-item>
          <!-- </div> -->
          <!-- </b-nav-form> -->
          <b-nav-item v-b-tooltip.hover title="Chat" class="mr-2">
            <b-img src="images/icons/chat.png"></b-img>
          </b-nav-item>
          <b-nav-item v-b-tooltip.hover title="Notifications" class="mr-2">
            <b-img src="images/icons/notifications.png"></b-img>
          </b-nav-item>
          <b-nav-item v-b-tooltip.hover title="Wardrobe" class="mr-2">
            <b-img src="images/icons/wardrobe.png"></b-img>
          </b-nav-item>
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-img class="rounded-circle profile-pic" src="images/test_data/islam.png"></b-img>
            </template>
            <b-dropdown-item>Profile</b-dropdown-item>
            <b-dropdown-item style="color: black;" @click="signOut">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "nav-bar",
  methods: {
    signOut() {
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped>
.tb-navbar {
  color: black !important;
  background-color: #fafafc !important;
  border-bottom: 1px solid #dbdbdb;
}

.dropdown-toggle::after {
  vertical-align: 0px !important;
}

.navbar-brand {
  margin-left: 20%;
}

.tb-navbar .logo {
  transform: scale(1.2);
  transition: 0.7s all;
}

.tb-navbar .logo:hover {
  transform: scale(1.3);
}

.search-container {
  display: inherit;
  width: fit-content;
  position: relative;
}

.profile-pic {
  height: 25px;
  width: 25px;
}

.remove-border {
  border-top: 0px;
}

@media screen and (max-width: 767px) {
  .navbar-brand {
    margin-left: 2%;
  }
  .tb-navbar .logo {
    transform: scale(1.1);
  }
  .tb-navbar .logo:hover {
    transform: scale(1.1);
  }
}
</style>