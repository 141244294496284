<template>
  <div id="livesScrollContainer" class="lives-bar-container">
    <b-img
      id="left-arrow-lives"
      @click="scrollStories('left')"
      class="nav-arrow pos-left d-none"
      src="images/icons/left-arrow.png"
    ></b-img>
    <div @scroll="rerenderArrows(null)" id="livesBar" class="lives-bar">
      <div id="livesBarScroll" class="lives">
        <div v-for="live in lives" :key="live.id" class="live-container mx-2 c-p">
          <figure
            v-b-modal="'live-' + live.id"
            class="position-relative tb-font-new"
            style="height: 200px;"
          >
            <b-img-lazy class="live-card tb-card-shadow" :src="live.image" />
            <b-img class="live-icon blink" src="images/icons/live.png" />
            <figcaption class="live-name text-left mt-2 ml-1">{{live.name}}</figcaption>
            <figcaption class="live-subtext text-left mt-1 ml-1">Started {{live.startedFrom}} ago.</figcaption>
          </figure>
          <b-modal :id="'live-' + live.id">
            <b-img-lazy class="live-video-container" src="images/test_data/live.gif"></b-img-lazy>
          </b-modal>
        </div>
      </div>
    </div>
    <b-img
      id="right-arrow-lives"
      @click="scrollStories('right')"
      class="nav-arrow pos-right"
      src="images/icons/right-arrow.png"
    ></b-img>
  </div>
</template>

<script>
export default {
  name: "lives-bar",
  methods: {
    rerenderArrows(newScrollLeft) {
      if (newScrollLeft == null)
        newScrollLeft = document.getElementById("livesBar").scrollLeft;

      if (newScrollLeft <= 30)
        document.getElementById("left-arrow-lives").classList.add("d-none");
      else
        document.getElementById("left-arrow-lives").classList.remove("d-none");

      const livesBarScroll = document.getElementById("livesBarScroll");
      const livesBarContainer = document.getElementById("livesScrollContainer");
      const maxScrollLeft =
        livesBarScroll.scrollWidth - livesBarContainer.clientWidth;

      if (newScrollLeft >= maxScrollLeft)
        document.getElementById("right-arrow-lives").classList.add("d-none");
      else
        document.getElementById("right-arrow-lives").classList.remove("d-none");
    },
    calculateScrollValue(direction) {
      const clearance = 0.7;
      const livesBar = document.getElementById("livesBar");
      const offset = livesBar.offsetWidth * clearance;
      const newScrollLeft =
        direction == "left"
          ? livesBar.scrollLeft - offset
          : livesBar.scrollLeft + offset;
      return newScrollLeft;
    },
    scrollStories(direction) {
      const livesBar = document.getElementById("livesBar");
      const newScrollLeft = this.calculateScrollValue(direction);
      this.rerenderArrows(newScrollLeft);
      livesBar.scrollLeft = newScrollLeft;
    }
  },
  data() {
    return {
      lives: [
        {
          id: 0,
          image: "images/test_data/lives/live1.png",
          name: "Hair products",
          startedFrom: "5 mins"
        },
        {
          id: 1,
          image: "images/test_data/lives/live2.png",
          name: "Bersheka tour",
          startedFrom: "13 mins"
        },
        {
          id: 2,
          image: "images/test_data/lives/live3.png",
          name: "WHAT A SALE!! 🤩",
          startedFrom: "21 mins"
        },
        {
          id: 3,
          image: "images/test_data/lives/live4.png",
          name: "New & Nice Quality 👌",
          startedFrom: "1 hour"
        },
        {
          id: 4,
          image: "images/test_data/lives/live5.png",
          name: "Help!😱 feedbacks",
          startedFrom: "1 hour"
        },
        {
          id: 5,
          image: "images/test_data/lives/live6.png",
          name: "ZARA: Buy 1 Get 1",
          startedFrom: "2 hours"
        },
        {
          id: 6,
          image: "images/test_data/lives/live7.png",
          name: "Must try 👉 cream",
          startedFrom: "3 hours"
        }
      ]
    };
  }
};
</script>

<style scoped>
.lives-bar-container {
  width: 100%;
  position: relative;
  background-color: #fff;
}

.lives-bar {
  scroll-behavior: smooth;
  position: relative;
  width: 100%;
  height: 220px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.live-container {
  display: flex;
  float: left;
  overflow: hidden;
}

.lives-bar::-webkit-scrollbar {
  display: none;
}

.lives {
  overflow-x: auto;
  white-space: nowrap;
  width: max-content;
  height: inherit;
}

.live-card {
  width: 144px;
  height: 160px;
  border: 2px solid #18191f;
}

.live-icon {
  position: absolute;
  top: 11px;
  left: 11px;
}

.scoll-pane::-webkit-scrollbar {
  display: none !important;
}

.live-name {
  color: black;
  font-weight: bolder;
  position: absolute;
  width: 200px;
  font-size: 1.05em;
}

.live-subtext {
  font-size: 0.85em;
  color: rgb(134, 130, 130);
  font-weight: 400;
  position: relative;
  width: 150px;
  top: 25px;
}

.live-video-container {
  margin: auto;
}

.live {
  /* Take the rest of the page */
  flex-grow: 1;
  /* Center align */
  display: flex;
  align-items: center;
  justify-content: center;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}
</style>