<template>
  <div class="h-100 w-100">
    <div class="w-100 text-right mb-4">Step {{stepNum}}/5</div>
    <div class="d-flex flex-column align-items-center my-3 p-4 w-100">
      <h1 class="title w-100 ta-l mt-1">
        Your Sizes
        <span class="subtitle">(Optional)</span>
      </h1>
      <h5
        class="title-sub-text ml-1 w-100 mb-2"
      >by choosing your sizes in clothes, it will helps you get your clothes faster.</h5>
      <div class="d-block preferences-scrollable-div" style="overflow-y: scroll; height: 55vh;">
        <div
          v-for="category in categories"
          :key="category.id"
          class="d-block my-4 tb-normal-normal text-center"
        >
          <span class="d-block w-100 my-2">{{category.description}}</span>
          <div
            @click="selectSizeForCategory(category.id, size.name)"
            v-for="size in category.sizes"
            :key="size.name"
            class="d-inline-block tb-square-btn-small mr-2 mb-2 c-p"
            :class="(size.selected)? 'tb-square-btn-small-selected': ''"
          >
            <span class="m-auto text-center" style="text-shadow: none;">{{size.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SizesSelection",
  props: {
    stepNum: {
      type: Number,
      default: 0
    }
  },
  components: {},
  mounted() {},
  methods: {
    selectSizeForCategory(categoryId, sizeName) {
      let targetCategory = this.categories.find(
        category => category.id === categoryId
      );
      let targetSize = targetCategory.sizes.find(
        size => size.name === sizeName
      );
      targetSize.selected = !targetSize.selected;
    }
  },
  data() {
    return {
      gender: null,
      categories: [
        {
          id: 0,
          description: "Tops like; T-shirt, blouse, pullover, jacket etc..",
          sizes: [
            { name: "S", selected: false },
            { name: "M", selected: false },
            { name: "L", selected: false },
            { name: "XL", selected: false },
            { name: "2XL", selected: false },
            { name: "3XL", selected: false },
            { name: "4XL", selected: false }
          ]
        },
        {
          id: 1,
          description: "Bottoms like; pants, skirts, shorts, jeans etc..",
          sizes: [
            { name: "S", selected: false },
            { name: "M", selected: false },
            { name: "L", selected: false },
            { name: "XL", selected: false },
            { name: "2XL", selected: false },
            { name: "3XL", selected: false },
            { name: "4XL", selected: false }
          ]
        },
        {
          id: 2,
          description: "Shoes like; heals, sneakers, ect...",
          sizes: [
            { name: "36", selected: false },
            { name: "37", selected: false },
            { name: "38", selected: false },
            { name: "39", selected: false },
            { name: "40", selected: false },
            { name: "41", selected: false },
            { name: "42", selected: false },
            { name: "43", selected: false },
            { name: "44", selected: false },
            { name: "45", selected: false },
            { name: "46", selected: false }
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.title {
  font-size: 2.5em;
  color: black;
  font-weight: 900;
}

.subtitle {
  font-size: 0.4em;
  color: #474a57;
  font-weight: 500;
}

.title-sub-text {
  font-weight: 600;
  font-size: 1em;
  color: #474a57;
}
</style>